<template>
  <base-section
    id="k-d-a-about-us"
    class="black"
    space="12"
  >
    <v-container>
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col
          cols="12"
          md="9"
          lg="8"
        >
          <component
            :is="item.strComponent"
            v-for="item in m_arrContents"
            :key="item.strText"
            :class="getTextClasses(item.eType , ['justify-start', 'white--text'])"
          >
            {{ item.strText }}
          </component>
        </v-col>

        <v-col
          v-if="$vuetify.breakpoint.lgAndUp"
          lg="2"
        />

        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <base-btn
            color="white"
            :to="{ name: 'KDAPortfolio' }"
          >
            {{ m_strButtonLabel }}
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import BusinessDetails from '@/mixins/business-details'
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'KDAAboutUs',

    mixins: [BusinessDetails, TextClass],

    data: () => ({
      m_strButtonLabel: 'More Info',
    }),

    computed: {
      m_arrContents () {
        return [
          {
            strText: 'ABOUT US',
            strComponent: 'h1',
            eType: this.eContentType.TITLE_3,
          },
          {
            strText: this.g_strBusinessDescriptionShort,
            strComponent: 'h6',
            eType: this.eContentType.SUBTITLE_3,
          },
        ]
      },
    },
  }
</script>
